import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginOrRegisterComponent } from './login-or-register.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoginOrRegisterComponent],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports : [LoginOrRegisterComponent],
})
export class LoginOrRegisterModule { }
