<div class="container">

    <form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
        <div class="flex justify-between mt-14 sm:flex-col sm:gap-4">
            <div class="w-[67%] sm:w-full">
                <div class="flex gap-3 items-center" [ngClass]="{'mb-5' : mapOnly}">
                    <button (click)="navigateBack()" type="button">
                        <app-back-icon *ngIf="mapOnly"/>
                    </button>
                    <h1 class="text-white text-[15px] font-bold mb-5 flex" [ngClass]="{'mb-0' : mapOnly}">{{ 'cart.cart-content' | translate }}</h1>
                </div>
                <div *ngIf="!hasCart()"> 
                    <div class="bg-white rounded-[10px] p-5 ">
                        <p class="font-bold mb-2">{{ 'cart.empty-cart' | translate }}</p>
                        <p *ngIf="!mapOnly">{{ 'cart.to-fixture' | translate }} <a class="text-[var(--red)]" [routerLink]="['/fixtures'] | localize">{{ 'cart.to-fixture-2' | translate }}</a>!</p>
                        <p *ngIf="mapOnly">{{ 'cart.mobile-to-fixture' | translate }}</p>
                    </div>
                </div>
                <div *ngIf="hasCart()" >
                    <!-- TICKETS -->
                    <div class="bg-white rounded-[10px] mb-3" *ngFor="let event of cart.items.events | keyvalue ">
                        <div class="justify-between px-10 py-5 grid grid-cols-[75%_25%] sm:flex sm:flex-col-reverse">
                            <!-- MATCH -->
                            <div class="flex justify-between items-center" *ngIf="isFixtureTypes(event.value.fixture, fixtureTypes().MATCH)">
                                <img class=" h-[40px]" [src]="event.value.fixture.home_team.logo" [alt]="event.value.fixture.home_team.name"/>
                                <div class="px-2">
                                    <p class="text-[15px] font-black text-center">{{ event.value.fixture.home_team.name }} - {{ event.value.fixture.away_team.name }} </p>
                                    <p class="text-sm text-center ">{{ event.value.fixture.venue}}</p>
                                </div>
                                <img class="h-[40px]" [src]="event.value.fixture.away_team.logo" [alt]="event.value.fixture.away_team.name"/>
                            </div>
                            <!-- OTHER EVENT -->
                            <div class="flex gap-3 items-center" *ngIf="isFixtureTypes(event.value.fixture, fixtureTypes().OTHER)">
                                <img class=" h-[40px]" *ngIf="event.value.fixture.image" [src]="event.value.fixture.image" [alt]="event.value.fixture.name"/>
                                <div>
                                    <p class="text-[15px] font-black text-left">{{ event.value.fixture.name }} </p>
                                    <p class="text-sm text-left ">{{ event.value.fixture.venue}}</p>
                                </div>
                            </div>
                            <div class="text-right sm:text-center sm:mb-3">
                                <p class="text-[13px]">{{ event.value.fixture.competition }}</p>
                                <p class="text-[13px]">{{ event.value.fixture.formatted_date }}</p>
                            </div>
                        </div>
                        <ul>
                            <app-ticket [key]="i" [cart]="cart" *ngFor="let ticket of event.value.tickets | keyvalue;  let last = last; let i = index" [ticket] = "ticket.value" [fixtureId]="event.value.fixture.id"></app-ticket> 
                        </ul>
                    </div>
                    <!-- TICKETS -->

                    <!-- SEASON PASSES -->
                    <div class="bg-white rounded-[10px] mb-3" *ngFor="let seasonPass of cart.items.season_passes| keyvalue ">
                        <div class="justify-between px-10 py-5 grid grid-cols-[75%_25%] sm:flex sm:flex-col-reverse">
                            <div class="flex justify-between items-center">
                                <div >
                                    <p class="text-[15px] font-black text-left">{{ seasonPass.value.details.name }}</p>
                                    <p class="text-sm text-left">{{ seasonPass.value.details.subtitle}}</p>
                                </div>
                            </div>
                        </div>
                        <ul>
                            <app-season-pass [key]="i"  [cart]="cart" *ngFor="let spass of seasonPass.value.season_passes | keyvalue;s let last = last; let i = index" [seasonPass] = "spass.value" [seasonPassSlug]="seasonPass.value.details.slug"></app-season-pass> 
                        </ul>
                    </div>
                    <!-- SEASON PASSES -->

                    <ng-container *ngIf="cart.need_to_select_payment_provider">
                        <h2 class="text-white text-[15px] font-bold mb-5 mt-5">{{ 'cart.payment-options' | translate }}</h2>
                        <div class="bg-white rounded-[10px] p-5">
                            <app-input-error-msg customCssClasses="pl-5 mb-4" [show]="isFieldInvalid('paymentMethod', 'required')" [errorMsg]="'cart.payment-option-required' | translate"></app-input-error-msg>
                            <div class="flex flex-col gap-5">
                                <div *ngIf="!isCashier()">
                                    <!-- CUSTOMER -->
                                    <app-radiobutton 
                                        type="radio" 
                                        [currentValue]="getValue('paymentMethod')" 
                                        (inputValueChangedEvent)="setValue('paymentMethod', $event)" 
                                        labelCssClasses="!text-black !text-[13px]" 
                                        value="simple_pay" 
                                        identifier="simple_pay" 
                                        type="radio" 
                                        [label]="'cart.simplepay-terms' | translate"
                                    />
                                    <!-- CUSTOMER -->
                                </div>
                                <ng-container *ngIf="isCashier()">
                                    <!-- CASHIER -->
                                    <div >
                                        <app-radiobutton 
                                            type="radio" 
                                            [currentValue]="getValue('paymentMethod')" 
                                            (inputValueChangedEvent)="setValue('paymentMethod', $event)" 
                                            labelCssClasses="!text-black !text-[13px]" 
                                            value="cash" 
                                            identifier="cash" 
                                            type="radio" 
                                            [label]="'cart.cash' | translate"></app-radiobutton>
                                    </div>
                                    <div >
                                        <app-radiobutton 
                                            type="radio" 
                                            [currentValue]="getValue('paymentMethod')" 
                                            (inputValueChangedEvent)="setValue('paymentMethod', $event)" 
                                            labelCssClasses="!text-black !text-[13px]" 
                                            value="card" 
                                            identifier="card" 
                                            type="radio" 
                                            [label]="'cart.card' | translate"></app-radiobutton>
                                    </div>
                                    <!-- CASHIER -->
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="w-[30%] sm:w-full">
                <div *ngIf="!auth.isUserLoggedIn">
                    <h2 class="text-white text-[15px] font-bold mb-5">{{ 'cart.login' | translate }}</h2>
                    <div class="bg-white rounded-[10px] pt-8 px-10 pb-5">
                        <h3 class="font-bold">{{ 'cart.not-logged-in' | translate }}</h3>
                        <p class="text-[#888888] text-[13px] mb-7">{{ 'cart.login-to-proceed' | translate }}</p>
                        <app-login-register-content></app-login-register-content>
                    </div>
                </div>
                <div *ngIf="auth.isUserLoggedIn && hasCart()">
                    <h2 class="text-white text-[15px] font-bold mb-5">{{ 'cart.summary' | translate }}</h2>
                    <div class="bg-white rounded-[10px] pt-8 px-10 pb-5" [ngClass]="{'mb-10' : mapOnly}">
                        <p class="font-bold mb-4 flex gap-3 items-center"><app-wallet></app-wallet> {{ auth.user.email }}</p>   
                        <p class="text-[#888888] text-[13px] mb-5 leading-4">{{ 'cart.tickets-will-be-available-via-app' | translate }}</p>
                        <p class="text-[13px] font-bold flex justify-between mb-5">{{ 'cart.amount-to-pay' }}: <span>{{ cart.total_price }}</span></p>   
                        <p class="text-[13px] font-bold mb-5">{{ 'cart.invoice-details' | translate }} / <button class="text-[var(--red)]" type="button" (click)="showInvoiceDetailsModal()">{{ 'general.edit' | translate }}</button></p>   
                        <p *ngIf="!invoiceDetails" class="text-[#888888] text-[13px] mb-5 leading-4">{{ 'cart.please-fill-invoice-details' | translate }}</p>   
                        <div *ngIf="invoiceDetails" class="mb-8">
                            <p class="text-[#888888] text-[13px]" *ngFor="let row of invoiceDetailsFormatted">{{ row }}</p>
                        </div>
                        <app-button [isProcessing] = "isRequesting">{{ actionButtonText }}</app-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
