export type Environment = 'development' | 'staging' | 'production';
export type Client = 'test' | 'szpari'; // Add other clients as needed

// Define the structure of the configuration map
export type ConfigMap = {
  [env in Environment]: {
    [client in Client]: () => Promise<any>;
  };
};

export const CONFIGURATION_MAP: ConfigMap = {
  development: {
    test: () => import('./development/clients/test/configuration'),
    szpari: () => import('./development/clients/szpari/configuration'),
  },
  staging: {
    test: () => import('./staging/clients/test/configuration'),
    szpari: () => import('./staging/clients/szpari/configuration'),
  },
  production: {
    test: () => import('./production/clients/test/configuration'),
    szpari: () => import('./production/clients/szpari/configuration'),
  },
};
