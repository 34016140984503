import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BackModule } from 'src/app/components/assets/icons/back/back.module';
import { TicketService } from 'src/app/services/Ticket/ticket.service';
import { SingleTicket } from 'src/app/interfaces/State/MyTickets/singleTicket';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { FixtureType } from 'src/app/enums/fixture/fixtureType';
import { Ticket } from 'src/app/interfaces/State/MyTickets/ticket';
import { DomSanitizer } from '@angular/platform-browser';
import { clearESeasonPass, clearETicket, setESeasonPass, setETicket } from 'src/app/actions/mytickets.actions';
import { SingleSeasonPass } from 'src/app/interfaces/State/MySeasonPasses/singleSeasonPass';
import { Location } from '@angular/common'
import { InputModule } from 'src/app/components/form/input/input.module';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TransferService } from 'src/app/services/Transfer/transfer.service';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { CheckboxModule } from 'src/app/components/form/checkbox/checkbox.module';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { SkeletonLoaderComponent } from 'src/app/components/skeleton-loader/skeleton-loader.component';
import { LocalizeRouterModule, LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-e-ticket',
  standalone: true,
  imports: [
    CommonModule, 
    BackModule, 
    RouterModule, 
    InputModule, 
    InputErrorMsgModule, 
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    SkeletonLoaderComponent,
    TranslateModule,
    LocalizeRouterModule,
  ],
  templateUrl: './e-ticket.component.html',
  styleUrls: ['./e-ticket.component.scss']
})
export class ETicketComponent implements OnInit, OnDestroy {
  public orderId!: string
  public seatUuid!: string
  public seasonPassSlug?: string
  public fixtureSlug?: string
  public eTicket?: SingleTicket
  public eSeasonPass?: SingleSeasonPass
  private _subscription?: Subscription
  private formSubmitAttempt: boolean = false;
  public error: string = ''
  public topError: string = ''
  public success: boolean = false
  public isTransfering: boolean = false
  public isRequesting: boolean = true
  public mapOnly: boolean = false
  
  public transferForm = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    terms: [false, [Validators.requiredTrue]],
  });
  
  constructor(
    private _route: ActivatedRoute,
    private _ticketService: TicketService,
    private _store: Store,
    private sanitizer: DomSanitizer,
    private location: Location,
    private router: Router,
    private _formBuilder: FormBuilder,
    private _transferService: TransferService,
    private _alertService: AlertService,
    private _localizeRouterService : LocalizeRouterService,
    private _translateService : TranslateService,
    private _modalService: ModalService,
  )
  {
    this.seatUuid = this._route.snapshot.paramMap.get('jegy')!

    this.fixtureSlug = this._route.snapshot.paramMap.get('merkozes') ?? undefined
    this.seasonPassSlug = this._route.snapshot.paramMap.get('berlet') ?? undefined
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  async ngOnInit() {
    this._store.dispatch(clearESeasonPass())
    this._store.dispatch(clearETicket())

    this._subscription = this._store.subscribe((state: any) => {
      this.eTicket = state.myTickets.eTicket
      this.eSeasonPass = state.mySeasonPasses.eSeasonPass
    })

    let res;

    if(this.fixtureSlug) {
      res = await this._ticketService.getTicketInformation(this.fixtureSlug, this.seatUuid)
    } else if(this.seasonPassSlug) {
      res = await this._ticketService.getSeasonPassInformation(this.seasonPassSlug, this.seatUuid)
    }

    if(res?.error) {
      this.topError = res?.error
      this._alertService.error(undefined, res?.error)
    }

    this.isRequesting = false
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
    this._store.dispatch(setETicket({ticket: {} as SingleTicket}))  
    this._store.dispatch(setESeasonPass({seasonPass: {} as SingleSeasonPass}))  
  }

  public fixtureTypes() {
    return FixtureType
  }

  public isFixtureTypes(fixture: any, eventType: string) {
    return eventType == fixture.event_type
  }

  public printIdentifier(ticket: Ticket) {
    return this.sanitizer.bypassSecurityTrustHtml(ticket.identifier_src)
  }

  public navigateBack() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      const myTickets = this._localizeRouterService.translateRoute('my-tickets');

      this.router.navigate([myTickets]);
    }
  }

  get queryString() {
    return this._route.snapshot.queryParams
  }

  public showPersonalDetails()
  {
    this._modalService.showCartTicketPersonalDetails({
      ticketId: this.seatUuid, 
      key: 1,
      fixtureId : this.fixtureSlug,
    })
  }

  async transfer() {
    if(this.isTransfering) return ;
    this.isTransfering = true
    this.formSubmitAttempt = true

    this.error = ""
    if(!this.transferForm.valid) {
      this.isTransfering = false
      return ;
    }
  
    let res = null
    if(this.fixtureSlug) {
      res = await this._transferService.transferTicket(this.fixtureSlug, this.seatUuid, this.email!.value!)
    } else if(this.seasonPassSlug){
      res = await this._transferService.transferSeasonPass(this.seasonPassSlug, this.seatUuid, this.email!.value!)
    }
     
    this.isTransfering = false

    if(res === true) {
      this.reset()
      this.success = true

      const successText = this.fixtureSlug ? this._translateService.instant('e-ticket.ticket-transfer-successful') : this._translateService.instant('e-ticket.season-pass-transfer-successful')

      this._alertService.success(successText, undefined, false)

      setTimeout(() => {
        const myTickets = this._localizeRouterService.translateRoute('my-tickets');

        this.router.navigate([myTickets])
      }, 2500)
      return ;
    }
    
    this.success = false
    this.error = res.error
  }

  get email() { return this.transferForm.get('email') }

  reset() {
    this.transferForm.reset()
    this.formSubmitAttempt = false
  }

  setValue(property: string, value: any) {
    this.transferForm.get(property)?.setValue(value)
  }

  isFieldInvalid(field: string, errorType: string = '') {
    return !this.transferForm.get(field)!.valid && 
    this.formSubmitAttempt &&
    (errorType == '' || this.transferForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    return !this.transferForm.get(field)!.valid && this.formSubmitAttempt
  }
}
