import { AfterViewInit, ApplicationRef, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fromEvent, Observable, Subject, Subscription, throttleTime, timer } from 'rxjs';
import { State as SectorState } from 'src/app/enums/stadium/sector/state';
import { Matrix } from 'src/app/interfaces/Class/Stadium/matrix';
import { Position } from 'src/app/interfaces/Class/Stadium/position';
import { Zoomer } from 'src/app/interfaces/Class/Stadium/zoomer';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { CommonState } from 'src/app/interfaces/State/Common/common-state';
import { Index } from 'src/app/interfaces/State/Fixture';
import { Index as SeasonPassIndex } from 'src/app/interfaces/State/SeasonPass';
import { SoldSeats } from 'src/app/interfaces/State/Seat/sold-seats';
import { Sector } from 'src/app/interfaces/State/Stadium/sector';
import { StadiumWithSectorsAndSeats } from 'src/app/interfaces/State/Stadium/stadium-with-sectors-and-seats';
import { FixtureService } from 'src/app/services/Fixture/fixture.service';
import { TicketService } from 'src/app/services/Ticket/ticket.service';
import * as d3 from "d3";
import { SeasonPassService } from 'src/app/services/SeasonPass/season-pass.service';
import { PurchaseType } from 'src/app/enums/stadium/purchase-type';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { AnyARecord } from 'dns';
import { environment } from 'src/environments/environment';
import { BackgroundMediaType } from 'src/app/enums/fixture/backgroundMediaType';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';
import { SectorData } from 'src/app/interfaces/State/Sector/sector-data';
import { UserType } from 'src/app/enums/user/userType';
import { DeletingSeat } from 'src/app/interfaces/State/Seat/deleting-seat';
import { StadiumService } from 'src/app/services/Stadium/stadium.service';
import { CookieService } from 'src/app/services/Cookie/cookie.service';
import { SectorBoundaries } from 'src/app/interfaces/State/Sector/sector-boundaries';
import { SectorBoundary } from 'src/app/interfaces/State/Sector/sector-boundary';
import { SvgArea } from 'src/app/interfaces/State/Stadium/svg-area';
import { pad } from 'crypto-js';
import { SeatDetails } from 'src/app/interfaces/State/Seat/seat-details';
import { HttpStatusCode } from '@angular/common/http';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';

@Component({
  selector: 'app-stadium',
  templateUrl: './stadium.component.html',
  styleUrls: ['./stadium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})

export class StadiumComponent implements OnInit, OnDestroy {
  public stadiumState: StadiumWithSectorsAndSeats = {} as StadiumWithSectorsAndSeats
  public commonState: CommonState = {} as CommonState
  public soldSeats: SoldSeats = {}
  public seatDetails: SeatDetails = {}
  public deletingSeats: DeletingSeat = {}
  public sectorData: SectorData = {} as SectorData
  public cart: Cart = {} as Cart
  public auth: Auth = {} as Auth
  public scale: number = 1.0
  public minScale: number = 1
  public maxScale: number = 0
  public isLoadingInitially: boolean = true
  public fixtureId: string = ""
  public seasonPassId: string = ""
  public openedFixture: Index = {} as Index
  public openedSeasonPass: SeasonPassIndex = {} as SeasonPassIndex
  private _subscription?: Subscription
  private _router_subscription?: Subscription
  private interval: any
  private interval2: any
  public shouldApplyTransition: boolean = true
  public canInteractWithSeat: boolean = true
  public showBlackLayer: boolean = false
  public mapOnly: boolean = true
  public sectorBoundaries: SectorBoundaries = {}
  readonly zoomScale = 1.5
  readonly scaleFactor: number = 0.05
  readonly maxDesktopScale: number = 10
  readonly maxMobileScale: number = 120
  public worker?: Worker
  public worker2?: Worker

  public stadium = {
    width: 0,
    height: 0,
  }

  public zoom = {
    zoomInPossible: true,
    zoomOutPossible: false,
  } as Zoomer

  public matrixSetInitially: boolean = false

  public matrix = {
    _0: 1,
    _1: 0,
    _2: 0,
    _3: 1,
    _4: 0,
    _5: 0
  } as Matrix

  public pos = {
    x: 0,
    y: 0,
  } as Position

  public dirty: boolean = false

  public lastScalingTime: number = 0

  public mouse = { x: 0, y: 0, oldX: 0, oldY: 0, button: false }

  public toolTip = { x: 0, y: 0, display: 'block', title: "", content: "" }

  public style = {}

  public purchaseType: PurchaseType = PurchaseType.TICKET

  public showTooltip: boolean = false

  public errorMsg?: string

  constructor(
    private _seasonPassService: SeasonPassService,
    private _ticketService: TicketService,
    private _stadiumService: StadiumService,
    private _store: Store,
    private _route: ActivatedRoute,
    private _fixtureService: FixtureService,
    private _router: Router,
    private _cdr: ChangeDetectorRef,
    private _ngZone: NgZone,
    private _alertService: AlertService,
    private _localizeRouterService: LocalizeRouterService,
  ) {
    this.mapOnly = this._route.snapshot.queryParamMap.get('only-map') ? true : false
  }

  ngOnInit(): void {
    this._init()
  }

  ngOnDestroy(): void {
    this._reset()
  }

  fitHeight(availableWidth: number, availableHeight: number, stadiumWidth: number, stadiumHeight: number) {
    const fittableHeight = availableWidth / (stadiumWidth / stadiumHeight)
    this.shouldApplyTransition = false
    const scale = availableHeight / fittableHeight > 1 ? 1 : availableHeight / fittableHeight
    this.scale = scale
    this.matrix._0 = scale
    this.matrix._3 = scale
    const y = ((fittableHeight - availableHeight) * scale) / 2
    this.matrix._5 = -1 * y
    this.matrix._4 = 0
    this.applyTo()
  }

  fitWidth(availableWidth: number, availableHeight: number, stadiumWidth: number, stadiumHeight: number) {
    const fittableHeight = availableWidth / (stadiumWidth / stadiumHeight)

    const scale = availableHeight / fittableHeight > 1 ? 1 : availableHeight / fittableHeight

    const y = ((fittableHeight - availableHeight) * scale) / 2

    this.matrix._5 = -1 * y
    this.matrix._0 = scale
    this.matrix._3 = scale
    this.minScale = scale
  }

  lastEventTimestamp: number = 0

  private terminateWorkersAndIntervals() 
  {
    if (this.interval) {
      window.clearInterval(this.interval);
    }
    if (this.interval2) {
      window.clearInterval(this.interval2);
    }
    if (this.worker) {
      this.worker.terminate()
      this.worker = undefined
    }

    if (this.worker2) {
      this.worker2.terminate()
      this.worker2 = undefined
    }
  }

  private _reset() {
    this._fixtureService.removeStates()
    this._seasonPassService.removeStates()
    this._stadiumService.removeStates();
    this._ticketService.removeSoldTicketsForFixture()
    this._ticketService.removeSectorDataForFixture()
    this._subscription?.unsubscribe()
    this._router_subscription?.unsubscribe()
    this.terminateWorkersAndIntervals()

    document.removeAllListeners?.('wheel')
    document.removeAllListeners?.('mousedown')
    document.removeAllListeners?.('mouseup')
    document.removeAllListeners?.('mousemove')
    document.removeAllListeners?.('touchstart')
    document.removeAllListeners?.('touchmove')
    document.removeAllListeners?.('touchend')

    const htmlElement = document.getElementsByTagName('html')[0]
    htmlElement.classList.remove("stadium-view");

    this.openedFixture = {} as Index
    this.openedSeasonPass = {} as SeasonPassIndex
    this.stadiumState = {} as StadiumWithSectorsAndSeats
  }

  public onWheelEventListener(e: WheelEvent) {
    if (e.ctrlKey) {
      e.preventDefault()
      e.stopPropagation();
      this.onWheel(e)
      return false;
    }

    return true
  }

  public onDocumentMouseDownListener(e: any) {
    this.onMouseDownEvent(e)
  }

  public onDocumentMouseMoveListener(e: any) {
    e.preventDefault()
    e.stopPropagation();
    this.onMouseMoveEvent(e)
  }

  public onDocumentMouseUpListener(e: any) {
    e.preventDefault()
    e.stopPropagation();
    this.onMouseUpEvent(e)
  }

  private async _init() {
    this._stadiumService.removeStates()

    window.scroll(0, 0)

    const htmlElement = document.getElementsByTagName('html')[0]
    htmlElement.classList.add("stadium-view");
    
    this._ngZone.runOutsideAngular(() => {
      document.addEventListener('wheel', (e) => this.onWheelEventListener(e), { passive: false, capture: false })
      document.addEventListener('mousedown', (e) => this.onDocumentMouseDownListener(e), { passive: false, capture: false })
      document.addEventListener('mousemove', (e) => this.onDocumentMouseMoveListener(e), { passive: false, capture: false })
      document.addEventListener('mouseup', (e) => this.onDocumentMouseUpListener(e), { passive: false, capture: false })

      document.addEventListener('touchstart', (e) => this.onTouchStartEvent(e), {passive:false, capture: false})
      document.addEventListener('touchend', (e) => this.onTouchEndEvent(e), {passive:false, capture: false})
      document.addEventListener('touchmove', (e) => this.onTouchMoveEvent(e), {passive:false, capture: false})
    })

    this._router_subscription = this._router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this._reset()
        if (val.url.indexOf('/merkozes/') !== -1 || val.url.indexOf('/berlet/') !== -1) {
          this._init()
        }
      }
    });

    this.maxScale = !this.isDesktop ? this.maxMobileScale : this.maxDesktopScale

    const element = document.getElementById('map-holder')

    if (element) {
      this.stadium.width = element!.getBoundingClientRect().width
      this.stadium.height = element!.getBoundingClientRect().height
    }

    this._subscription = this._store.subscribe((state: any) => {
      this.commonState = state.common
      this.stadiumState = state.stadium.data
      this.soldSeats = state.seats.soldSeats
      this.deletingSeats = state.seats.deletingSeats
      this.seatDetails = state.seats.seatDetails
      this.sectorData = state.sector.sectorData
      this.cart = state.cart
      this.openedFixture = state.fixtures.openedFixture
      this.openedSeasonPass = state.seasonPasses.openedSeasonPass
      this.auth = state.auth

      const opened = this.openedFixture && Object.keys(this.openedFixture).length ? this.openedFixture : this.openedSeasonPass

      const showSeatMap = opened?.show_seat_map != null && opened?.show_seat_map != undefined ? opened?.show_seat_map : null
      if (this.sectorsLoaded() || showSeatMap == false) {
        setTimeout(() => {
          this.isLoadingInitially = false
          this._cdr.detectChanges()
        }, 100)
      }
      this._cdr.detectChanges()
    })

    /* production */
    if (environment.production) {
      /* SOLD SEATS */
      if (typeof Worker !== 'undefined') {
        this.worker = new Worker("./assets/worker.js");

        this.worker!.postMessage(10000)

        this.worker!.onmessage = () => {
          this.getSoldTickets()
        }
      }
      else {
        this.interval = window.setInterval(() => {
          this.getSoldTickets()
        }, 10000);
      }

      /* SECTOR STATUS */
      if (typeof Worker !== 'undefined') {
        this.worker2 = new Worker("./assets/worker.js");

        this.worker2!.postMessage(5 * 60000)

        this.worker2!.onmessage = () => {
          this.getSectorData()
          if (this.isFixture()) {
            this._fixtureService.getFixtureWithSeats(this.fixtureId)
          }
        }
      }
      else {
        this.interval2 = window.setInterval(() => {
          this.getSectorData()
          if (this.isFixture()) {
            this._fixtureService.getFixtureWithSeats(this.fixtureId)
          }
        },5 * 60000);
      }
    }

    /* fixture */
    if (this.isFixture()) {
      this.purchaseType = PurchaseType.TICKET
      this.fixtureId = this._route.snapshot.paramMap.get('id')!
      try {
        this.errorMsg = undefined
        await this._fixtureService.getFixture(this.fixtureId)
        this._ticketService.getSoldTicketsForFixture(this.fixtureId)
        this._ticketService.getSectorDataForFixture(this.fixtureId)
      } catch (e: any) {
        /* 422 error */
        if(e.status == HttpStatusCode.UnprocessableEntity) {
          this.isLoadingInitially = false
          this.errorMsg = e.error.error
          this._cdr.detectChanges()

          this.terminateWorkersAndIntervals()
          this._alertService.error(undefined, e.error.error)
        } else {
          this.errorMsg = e.error.error
        }
      }
    }
    /* season pass */
    else if (this.isSeasonPass()) {
      this.purchaseType = PurchaseType.SEASON_PASS
      this.seasonPassId = this._route.snapshot.paramMap.get('id')!
     
      try {
        this.errorMsg = undefined
        await this._seasonPassService.getSeasonPass(this.seasonPassId)
        this._ticketService.getSoldTicketsForSeasonPass(this.seasonPassId)
        this._ticketService.getSectorDataForSeasonPass(this.seasonPassId)
      } catch (e: any) {
        /* 422 error */
        if(e.status == HttpStatusCode.UnprocessableEntity) {
          this.isLoadingInitially = false
          this.errorMsg = e.error.error
          this._cdr.detectChanges()
          this.terminateWorkersAndIntervals()
          this._alertService.error(undefined, e.error.error)
        } else {
          this.errorMsg = e.error.error
        }  
      }
    }
  }

  private getSectorData = () => {
    if (this.isFixture()) {
      this._ticketService.getSectorDataForFixture(this.fixtureId)
    } else if (this.isSeasonPass()) {
      this._ticketService.getSectorDataForSeasonPass(this.seasonPassId)
    }
  }

  private getSoldTickets = () => {
    if (this.isFixture()) {
      this._ticketService.getSoldTicketsForFixture(this.fixtureId)
      //this._ticketService.getSectorDataForFixture(this.fixtureId)
    } else if (this.isSeasonPass()) {
      this._ticketService.getSoldTicketsForSeasonPass(this.seasonPassId)
     // this._ticketService.getSectorDataForSeasonPass(this.seasonPassId)
    }
  }

  isFixture(): boolean {
    return this.checkRouteType("fixture")
  }

  isSeasonPass(): boolean {
    return this.checkRouteType("season-pass")
  }

  checkRouteType(type: string): boolean {
    const firstPathSegment = this._route.snapshot.url[0].path;

    const fixturePath = this._localizeRouterService.translateRoute('fixture');
    const seasonPassPath = this._localizeRouterService.translateRoute('season-pass');

    if (firstPathSegment === fixturePath) {
      return type == "fixture" ? true : false
    } else if (firstPathSegment === seasonPassPath) {
     return type == "season-pass" ? true : false
    }

    return false
  }

  private seatsLoaded() {
    if (!this.stadiumState.sectors) return false

    const sectorWithSeatsFound = Object.keys(this.stadiumState.sectors).find((sectorId: any) => {
      return this.stadiumState.sectors[sectorId].s !== undefined
    })
    return sectorWithSeatsFound ? true : false
  }

  private sectorsLoaded() {
    if (!Object.keys(this.sectorData).length || !this.stadiumState.sectors || !Object.keys(this.stadiumState.sectors).length) return false

    return true
  }

  /* ZOOMING DESKTOP & MOBILE */
  zoomIn(zoomScale: number = this.zoomScale) {
    zoomScale = zoomScale < this.zoomScale ? this.zoomScale : zoomScale
    this.scaleAt({ x: 0, y: 0 }, zoomScale)
    setTimeout(() => {
      this.isPinchZooming = false
    }, 200)
  }

  zoomOut(zoomScale: number = this.zoomScale) {
    zoomScale = zoomScale < 1 ? zoomScale + 1 : zoomScale

    this.scaleAt({ x: 0, y: 0 }, 1 / zoomScale)

    setTimeout(() => {
      this.isPinchZooming = false
    }, 200)
  }

  zoomReset() {
    this.shouldApplyTransition = true
    this.scale = 1
    this.pos.x = 0
    this.pos.y = 0
    this.update()
  }
  /* ZOOMING DESKTOP & MOBILE */

  scaleAt(at: { x: number, y: number }, amount: number) { // at in screen coords
    this.shouldApplyTransition = true
    if (this.dirty) {
      return;
    }
    if (this.scale * amount > this.maxScale || this.scale * amount < this.minScale) {
      if (this.scale * amount > this.maxScale) this.zoom.zoomInPossible = false
      return;
    }
    this.zoom.zoomInPossible = true
    this.zoom.zoomOutPossible = true

    this.scale *= amount;
    this.pos.x = at.x - (at.x - this.pos.x) * amount;
    this.pos.y = at.y - (at.y - this.pos.y) * amount;
    this.dirty = true

    this.update()
  }

  update() {
    this.matrix = {
      ...this.matrix,
      _0: this.scale,
      _3: this.scale,
      _2: 0,
      _1: 0,
      _4: this.pos.x,
      _5: this.pos.y,
    }

    const matrix0 = Math.round((this.matrix._0 + Number.EPSILON) * 100) / 100

    this.zoom.zoomInPossible = this.matrix._0 < this.maxScale ? true : false
    this.zoom.zoomOutPossible = matrix0 > 1 ? true : false


    this._cdr.detectChanges()
    this.lastScalingTime = Date.now()
    this.dirty = false
  }

  get mapStyle() {
    return { 'transform': 'matrix(' + this.matrix._0 + ',' + this.matrix._1 + ',' + this.matrix._2 + ',' + this.matrix._3 + ',' + this.matrix._4 + ',' + this.matrix._5 + ')' }
  }

  get tooltipStyle() {
    let left = this.toolTip.x
    let top = this.toolTip.y

    return {
      'transform': 'translateX(-50%) translateY(calc(-100% - 10px))',
      'left': left + 'px',
      'top': top + 'px',
      'display': this.toolTip.display,
    }
  }

  /* MOBILE MOVING */
  //@HostListener('document:touchstart', ['$event'])
  onTouchStartEvent($event: any) {
    
    this.previousDistance = 0

    /* scaling is handled by gesture event handlers */
    if ($event.scale != undefined && $event.scale != 1) {
      $event.preventDefault()
      return;
    }

    if($event.touches.length == 2) {
      $event.preventDefault()
    }

    this.canInteractWithSeat = false
    this.mouse.button = true
    this.mouse.x = this.getPageXFromEvent($event)
    this.mouse.y = this.getPageYFromEvent($event)
  }

  getPageXFromEvent($event: any) {
    return $event.pageX ?? $event.touches[0].pageX
  }

  getPageYFromEvent($event: any) {
    return $event.pageY ?? $event.touches[0].pageY
  }

  getClientXFromEvent($event: any) {
    return $event.clientX ?? $event.touches[0].clientX
  }

  getClientYFromEvent($event: any) {
    return $event.clientY ?? $event.touches[0].clientY
  }

  hasScalingExpired() {
    return Date.now() - this.lastScalingTime > 100
  }

  hasScalingExpiredLong() {
    return Date.now() - this.lastScalingTime > 400
  }

  //@HostListener('document:touchmove', ['$event'])
  onTouchMoveEvent($event: any) {
    $event?.preventDefault()

    const element = document.getElementById('map-holder')
    const zoomingOnMap = element?.contains($event.target as Node)

    if (!zoomingOnMap || !this.mouse.button || !this.hasScalingExpiredLong()) {
      return;
    }

    if (($event.scale != undefined && $event.scale != 1) || $event.touches.length == 2) {
      if (this.hasScalingExpiredLong()) {
        this.canInteractWithSeat = false
        this.isPinchZooming = true

        if (this.isZoomingIn($event)) {
          //this.zoomIn($event.scale ?? null)
          this.zoomIn($event.scale ?? undefined)
        } else if (this.isZoomingOut($event)) {
          //this.zoomOut($event.scale ?? null)
          this.zoomOut($event.scale ?? undefined)
        }
      }
    }
    /* movement */
    else {
      this.processMouseEvent($event)
    }
  }


  public previousDistance: number = 0;

  private isZoomingIn(event: any) {
    /* IOS */
    if (event.scale !== undefined && event.scale > 1) {
      return true;
    } else if (event.scale !== undefined && event.scale < 1) {
      return false;
    }

    /* ANDROID */
    const finger1 = event.touches[0];
    const finger2 = event.touches[1];

    const currentDistance = Math.sqrt(
      Math.pow(finger2.clientX - finger1.clientX, 2) +
      Math.pow(finger2.clientY - finger1.clientY, 2)
    );

    if (currentDistance > this.previousDistance && this.previousDistance != 0) {
      this.previousDistance = currentDistance
      return true
    }

    return false;
  }

  private isZoomingOut(event: any) {
    /* IOS */
    if (event.scale !== undefined && event.scale < 1) {
      return true;
    } else if (event.scale !== undefined && event.scale > 1) {
      return false;
    }

    /* ANDROID */
    const finger1 = event.touches[0];
    const finger2 = event.touches[1];
    const currentDistance = Math.sqrt(
      Math.pow(finger2.clientX - finger1.clientX, 2) +
      Math.pow(finger2.clientY - finger1.clientY, 2)
    );

    if (currentDistance < this.previousDistance && this.previousDistance != 0) {
      this.previousDistance = currentDistance
      return true
    }
    this.previousDistance = currentDistance
    return false;
  }

  //@HostListener('document:touchend', ['$event'])
  onTouchEndEvent($event: any) {
    if ($event.scale != 1) return;

    if (this.mouse.button) {
      this.mouse.button = false
      this.previousDistance = 0
    }
  }
  /* MOBILE MOVING */

  /* DESKTOP MOVING */
  onMouseDownEvent(event: MouseEvent) {
    const element = document.getElementById('map-holder')

    const movingOnMap = element?.contains(event.target as Node)

    if (!movingOnMap) return;

    event.preventDefault()
    event.stopPropagation();

    this.canInteractWithSeat = false
    this.mouse.button = true
    this.mouse.x = event.clientX
    this.mouse.y = event.clientY
  }

  onMouseUpEvent(event: MouseEvent) {
    if (this.isPinchZooming) {
      event.preventDefault()

      if (!this.isDesktop) {
        this.canInteractWithSeat = true
        this.mouse.button = false

        this._cdr.detectChanges()
      }
      return;
    }

    if (this.isMoving) {
      this.isMoving = false
      this.mouse.button = false
      event.preventDefault()

      if (!this.isDesktop) {
        this.canInteractWithSeat = true
        this.mouse.button = false

        this._cdr.detectChanges()
      }

      return;
    }

    if (this.mouse.button) {
      this.canInteractWithSeat = true
      this.mouse.button = false

      this._cdr.detectChanges()
    }
  }

  public isPinchZooming: boolean = false
  public isMoving: boolean = false

  onMouseMoveEvent(event: MouseEvent) {
    const element = document.getElementById('map-holder')
    const zoomingOnMap = element?.contains(event.target as Node)
    if (!zoomingOnMap) {
      this.hideToolTip()
      this.showTooltip = false
      return;
    }

    this.processMouseEvent(event)
    this.processToolTip(event)
  }

  hideToolTip() {
    this.toolTip.display = 'none'
    this._cdr.detectChanges()
  }

  processToolTip(event: any) {
    this.toolTip = {
      x: event.layerX,
      y: event.layerY,
      display: this.shouldShowToolTip(event) ? 'block' : 'none',
      title: event.target.dataset.title,
      content: event.target.dataset.content,
    }

    this._cdr.detectChanges()
  }

  private shouldShowToolTip(event: any): Boolean {
    const classList = event.target.classList ? Array.from(event.target.classList) : []

    /* if mobile and sector then hide */
    if (!this.isDesktop && (classList.includes('sector') || classList.includes('sector-text'))) {
      return false
    }

    return classList.includes('sector') || classList.includes('seat') || classList.includes('seat-text') || classList.includes('sector-text')
  }

  processMouseEvent(event: MouseEvent) {
    if (this.mouse.button && this.hasMouseMoved(event)) {
      this.isMoving = true
      this.shouldApplyTransition = false
      const offsetX = (this.getClientXFromEvent(event) ?? this.getPageXFromEvent(event)) - this.mouse.x
      //balra megyünk 
      if (offsetX > 0 && this.canMoveMapLeft()) {
        this.setX(event, offsetX)
      }
      //jobbra megyünk
      else if (offsetX < 0 && this.canMoveMapRight()) {
        this.setX(event, offsetX)
      }

      const offsetY = (this.getClientYFromEvent(event)  ?? this.getPageYFromEvent(event)) - this.mouse.y
      //felfelé megyünk
      if (offsetY > 0 && this.canMoveMapUp()) {
        this.setY(event, offsetY)
      }
      //lefelé megyünk
      else if (offsetY < 0 && this.canMoveDown()) {
        this.setY(event, offsetY)
      }
    }
  }

  setX(event: MouseEvent, offsetX: number) {
    this.matrix._4 = (this.matrix._4 + offsetX)
    this.mouse.x = (this.getClientXFromEvent(event) ?? this.getPageXFromEvent(event))
    this.pos.x += offsetX
    this._cdr.detectChanges()
  }

  setY(event: MouseEvent, offsetY: number) {
    this.matrix._5 = (this.matrix._5 + offsetY)
    this.mouse.y = (this.getClientYFromEvent(event) ?? this.getPageYFromEvent(event))
    this.pos.y += offsetY
    this._cdr.detectChanges()
  }

  canMoveMapLeft() {
    return true;
    return (this.matrix._4 * this.scale) < (this.stadium.width * this.scale * (this.scale / 2.5))
  }

  canMoveMapRight() {
    return true;
    return (this.matrix._4 * this.scale * -1) < (this.stadium.width * this.scale * (this.scale / 2.5) * 1.1)
  }

  canMoveMapUp() {
    return true;
    return (this.matrix._5 * this.scale) < (this.stadium.height * this.scale * (this.scale / 2.5) * .8)
  }

  canMoveDown() {
    return true;
    return (this.matrix._5 * this.scale * -1) < (this.stadium.height * this.scale * (this.scale / 2.5) * .8)
  }

  hasMouseMoved(event: MouseEvent): Boolean {
    return (this.mouse.x != this.getClientXFromEvent(event) ||  this.mouse.y != this.getClientYFromEvent(event)) ||
    (this.mouse.x != this.getPageXFromEvent(event) || this.mouse.y != this.getPageYFromEvent(event))
  }
  /* DESKTOP MOVING */

  getSectorCssClasses(sector: Sector): string {
    switch (sector.state) {
      case SectorState.available1: return 'available-1'; break;
      case SectorState.available2: return 'available-2'; break;
      case SectorState.available3: return 'available-3'; break;
      case SectorState.soldout: return 'soldout'; break;
      case SectorState.unavailable: return 'unavailable'; break;
      default: return ''; break;
    }
  }

  editingTicketTypeEventFn(event: boolean) {
    this.showBlackLayer = event
  }

  editingSeasonPassTypeEventFn(event: boolean) {
    this.showBlackLayer = event
  }

  /* zoom to clicked sector */
  zoomToSector(event: any) {
    this.shouldApplyTransition = true
    let { x, y, width, height } = event.target.getBoundingClientRect();

    const mapHolder = document.getElementById('map-holder')

    const boundary = mapHolder!.getBoundingClientRect();
    const layerX = (x - boundary.x)
    const layerY = (y - boundary.y)

    if (mapHolder) {
      const x = layerX - (mapHolder?.clientWidth / 2)
      const y = layerY - (mapHolder?.clientHeight / 2)

      this.scaleToPosition({ x, y, width, height }, 10);
      this.applyTo();
    }
  }

  /* TEST */
  onWheel(event: any) {
    const element = document.getElementById('map-holder')
    const header = document.getElementsByTagName('header')[0]
    const informations = document.getElementById('informations')
    const zoomingOnMap = element?.contains(event.target as Node)

    if (zoomingOnMap && element && informations && header) {
      const x = event.layerX - (element?.clientWidth / 2)
      const y = event.layerY - (element?.clientHeight / 2)
      if (event.deltaY < 0) {
        this.myScaleAt({ x, y }, 1.1);
        this.applyTo();
      } else {
        this.myScaleAt({ x, y }, 1 / 1.1);
        this.applyTo();
      }
    }

    event.preventDefault();
  }

  myScaleAt(at: { x: number, y: number }, amount: number) { // at in screen coords
    if (this.dirty) { this.myUpdate() }
    this.scale *= amount;
    this.pos.x = at.x - (at.x - this.pos.x) * amount
    this.pos.y = at.y - (at.y - this.pos.y) * amount
    this.dirty = true
  }

  /*scaleToPosition(coord: { x: number, y: number, width: number, height: number }, scale: number) {
    this.shouldApplyTransition = true
    if (this.dirty) { this.myUpdate() }
    
    this.pos.x = (- 1 * (scale) * (coord.x - (this.scale * this.pos.x)))
    this.pos.y = (- 1 * (scale) * (coord.y - (this.scale * this.pos.y)))
    this.scale = scale

    this.dirty = true
  }*/

  /* scale to given position */
  scaleToPosition(coord: { x: number, y: number, width: number, height: number }, zoom: number) {
    if (this.dirty) { this.update() }

    this.scale = zoom

    this.pos.y = ((this.matrix._5 - coord.y) / this.matrix._0 * zoom) - (coord.height / this.matrix._0 / 2 * zoom)
    this.pos.x = ((this.matrix._4 - coord.x) / this.matrix._0 * zoom) - (coord.width / this.matrix._0 / 2 * zoom)

    this.dirty = true
    this.applyTo();
  }

  myUpdate() {
    this.dirty = false;
    this.matrix._3 = this.matrix._0 = this.scale
    this.matrix._2 = this.matrix._1 = 0
    this.matrix._4 = this.pos.x
    this.matrix._5 = this.pos.y
  }

  applyTo() {
    if (this.dirty) { this.update() }

    this._cdr.detectChanges()
  }

  get canInteractWithSeatFn() {
    return this.canInteractWithSeat
  }

  get isDesktop() {
    return window.outerWidth > 600
  }

  hasPictureBackground() {
    return !this.openedFixture.show_seat_map && this.openedFixture.background_media_type == BackgroundMediaType.IMAGE && this.openedFixture.background_media
  }

  hasVideoBackground() {
    return window.outerWidth > 600 && !this.openedFixture.show_seat_map && this.openedFixture.background_media_type == BackgroundMediaType.VIDEO && this.openedFixture.background_media
  }

  showStadium() {
    if (!this.entityLoaded()) return false
    return (this.openedFixture && this.openedFixture.show_seat_map) || (this.openedSeasonPass && this.openedSeasonPass.show_seat_map)
  }

  entityLoaded() {
    return (this.openedFixture && Object.keys(this.openedFixture).length > 0) ||
      (this.openedSeasonPass && Object.keys(this.openedSeasonPass).length > 0) ? true : false
  }

  protected isCashier() {
    return this.auth.user.type == UserType.CASHIER
  }

  public frame = {
    topLeft: { x: 0, y: 0 },
    bottomRight: { x: 0, y: 0 },
  }

  public centerPointOfZoom = {
    x: 0,
    y: 0,
  }

}
