<footer class="bg-[#051623] py-6">
    <div class="container">
        <section class="flex gap-14 sm:flex-col sm:gap-2 sm:text-center">
            <p class="text-white font-bold text-sm">{{ 'footer.disclaimer' | translate }}</p>
            <a [routerLink]="['/terms'] | localize" class="text-white font-bold text-sm">{{ 'footer.terms' | translate }}</a>
            <a [routerLink]="['/privacy'] | localize" class="text-white font-bold text-sm">{{ 'footer.privacy' | translate }}</a>
            <a href="mailto:info@csakjegyek.hu" class="text-white font-bold text-sm">{{ 'footer.customer-service' | translate }}</a>
        </section>
    </div>
</footer>
