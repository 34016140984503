<ul class="flex gap-[5px] px-12">
    <li *ngFor="let item of items; index as index" class="h-[6px] w-[61px] linear rounded-[4.5px] transition-[background-position] duration-500 bg-gradient-to-l from-gray from-50% to-secondary to-50% bg-200%" :class="{ 'bg-left': (index + 1) < activeTab, 'bg-right': (index + 1) >= activeTab, }"></li>
</ul>
<div class="flex flex-col gap-[30px] select-none">
<template *ngFor="let question of items; index as index">
    <div *ngIf="(index + 1) === activeTab" class="mt-[30px] px-12" >
    <h1 class="text-[13px] font-bold mb-[30px]">{{ question.name }}</h1>
    <div class="flex flex-col gap-[15px] w-full">
        <div
        *ngFor="let answer of question.answers"
        tabindex="0"
        :key="answer.id"
        class="cursor-pointer focus:animate-pulse desktop:focus:bg-[var(--red)] focus:bg-[var(--red)] max-h-fit bg-gray-400 desktop:hover:bg-gray-600 py-3 px-5 align-center text-base rounded-[5px]"
        (click)="handleAnswerSelected($event, answer.id, question.id)"
        >
        {{ answer.name }}
        </div>
    </div>
    </div>
</template>
<div *ngIf="isEnded()" class="mt-[30px]">
    <div name="result">{{ 'tabs-holder.ok' | translate }}</div>
</div>
</div>
