import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleSeasonTicketComponent } from './single-season-ticket.component';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { LinkButtonModule } from 'src/app/components/common/link-button/link-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

@NgModule({
  declarations: [SingleSeasonTicketComponent],
  imports: [
    CommonModule,
    ButtonModule,
    LinkButtonModule,
    TranslateModule,
    LocalizeRouterModule,
  ],
  exports: [SingleSeasonTicketComponent],
})
export class SingleSeasonTicketModule { }
