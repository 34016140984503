<div class="flex fixed top-0 left-0 h-screen w-screen items-end dark-bg blurred z-[9]">
        <div class="px-16 py-12 bg-white w-full border rounded-t-[10px] md:px-4 md:py-6 sm:px-4 sm:py-6">
            <div class="container">
                <div class="flex justify-between sm:flex-col">
                    <div class="w-[70%] sm:w-full">
                        <div class="flex gap-4 sm:flex-col">
                            <div class="flex gap-3 items-center">
                                <img src="/assets/images/cookie.jpg" class="w-[130px] sm:h-[50px] sm:w-auto">
                                <h1 class="font-black text-[22px] sm:block hidden">{{ 'cookie.cookie-information' | translate }}</h1>
                            </div>
                            <div >
                                <h1 class="font-black text-[22px] sm:hidden">{{ 'cookie.cookie-information' | translate }}</h1>
                                <p class="text-[14px] mt-[10px] sm:mt-0">{{ 'cookie.cookie-text' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col sm:mt-5 gap-3 justify-center">
                            <app-button class="sm-md:w-full" (click)="acceptCookie()" [style]="'bg-red'">{{ 'cookie.accept' | translate }}</app-button>
                            <a [href]="['/privacy'] | localize" target="_blank" class="text-center text-sm underline text-gray-700">{{ 'cookie.detailed-description' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
</div>
