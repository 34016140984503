import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { ResetPasswordInterface } from 'src/app/interfaces/ApiRequest/reset-password-interface';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorMsgModule,
    InputModule,
    ButtonModule,
    RouterModule,
    TranslateModule,
  ],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  error: string = ''
  isRequesting: boolean = false
  success: boolean = false
  private formSubmitAttempt: boolean = false;
  @Output() showRegisterFormEvent: EventEmitter<string> = new EventEmitter();
  @Output() showForgotPasswordFormEvent: EventEmitter<string> = new EventEmitter();

  resetPasswordForm = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
    password_confirmation: ['', [Validators.required]],
    token: [''],
  });

  constructor(
    private _modalService: ModalService,   
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _activatedRoute: ActivatedRoute,
  ) {
    this._activatedRoute.queryParams
    .subscribe(params => {
      this.resetPasswordForm.get('token')?.setValue(params['token'])
    }
  );
  }

  hideModal() {
    this._modalService.hideModal()
  }
  
  setValue(property: string, value: any) {
    this.resetPasswordForm.get(property)?.setValue(value)
  }

  showForgotPasswordForm() {
    this.showForgotPasswordFormEvent.emit()
  }

  showRegisterForm() {
    this.showRegisterFormEvent.emit();
  }

  async onSubmit() {
    if(this.isRequesting) return ;
    this.isRequesting = true
    this.formSubmitAttempt = true

    this.error = ""
    if(!this.resetPasswordForm.valid) {
      this.isRequesting = false
      return ;
    }

    const res = await this._authService.resetPassword(this.resetPasswordForm.value as ResetPasswordInterface)

    this.isRequesting = false
    if(res === true) {
      this.reset()
      this.success = true
      return ;
    }

    this.success = false
    this.error = res
  }

  reset() {
    this.resetPasswordForm.reset()
    this.formSubmitAttempt = false
  }

  isFieldInvalid(field: string, errorType: string = '') {
    return !this.resetPasswordForm.get(field)!.valid && 
    this.formSubmitAttempt &&
    (errorType == '' || this.resetPasswordForm?.get(field)?.errors?.[errorType])
  }

  isFieldInvalidAtAll(field: string) {
    return !this.resetPasswordForm.get(field)!.valid && this.formSubmitAttempt
  }

  showLoginModal() {
    this._modalService.showLoginModal()
  }

  //get email() { return this.resetPasswordForm.get('email') }
  //get password() { return this.resetPasswordForm.get('password') }
  //get passwordConfirmation() { return this.resetPasswordForm.get('password_confirmation') }

}
