<p *ngIf="error && !success" class="text-[var(--red)] text-center mb-8 break-words" [innerHTML]="error"></p>

<form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
    <ng-container *ngIf="!success">
        <div class="mb-5">
            <app-input [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''" [placeholder]="'validation.email-address' | translate" (inputValueChangedEvent)="setValue('email', $event)"></app-input>
            <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'required')" [errorMsg]="'validation.email-address-required' | translate"></app-input-error-msg>
            <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'email')" [errorMsg]="'forgot-password.email-format-invalid' | translate"></app-input-error-msg>
        </div>
        <div >
            <app-button [isProcessing] = "isRequesting">{{ "forgot-password.send-password-reminder" | translate }}</app-button>
        </div>
    </ng-container>
    <p *ngIf="success" class="text-center border-b-[#dbd9d9] border-b pb-4">{{ 'forgot-password.password-reminder-sent-succesfully' | translate }}</p>   
    <button type="button" *ngIf="!isUserLoggedIn" (click)="showRegisterForm()" class="text-[#888888] mx-auto cursor-pointer mt-5 mb-0 block text-[13px] text-center" [innerHTML]="'forgot-password.lets-register' | translate"></button>   
    <button type="button" *ngIf="!isUserLoggedIn" (click)="showLogin()" class="text-[#888888] mx-auto cursor-pointer mt-2 mb-0 block text-[13px] text-center" [innerHTML]="'forgot-password.back-to-login' | translate"></button>   

</form> 