import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { UserType } from 'src/app/enums/user/userType';
import { Auth } from 'src/app/interfaces/State/Auth/auth';
import { AuthService } from 'src/app/services/Auth/auth.service';
import { ModalService } from 'src/app/services/Modal/modal.service';
import { NavbarService } from 'src/app/services/Navbar/navbar.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  //host: {'(document:click)': 'onClick($event)',},
})
export class ProfileMenuComponent implements OnInit, OnDestroy {
  public auth: Auth = {
    isUserLoggedIn: false,
  } as Auth

  private _subscription?: Subscription

  constructor(
    private _store: Store, 
    private _modalService: ModalService,   
    private _authService: AuthService,
    private _router: Router,
    private _navbarService: NavbarService,
    private _eref: ElementRef,
  ) {
    _router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        this.hideProfileMenu()
      }
     });
  }

  ngOnInit(): void {
    this._subscription = this._store.subscribe((state: any) => {
      if(this.auth.isUserLoggedIn && !state.auth.isUserLoggedIn) {
        this.hideProfileMenu()
      }
      this.auth = state.auth
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  logout() {
    this._authService.logout()
  }

  invoiceDetails() {
    this.hideProfileMenu()
    this._modalService.showInvoiceModal()
  }

  hideProfileMenu() {
    this._navbarService.hideProfileMenu()
  }

  isCashier() {
    return this.auth.isUserLoggedIn && this.auth.user.type == UserType.CASHIER
  }

  showPasswordResetModal() {
    this.hideProfileMenu()
    this._modalService.showLoginModal()
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const clickedElement = event.target as HTMLElement;
    const isInsideComponent = this._eref.nativeElement.contains(event.target);
    const isMyAccountButton = clickedElement.id === 'my-account';

    if (!isInsideComponent && !isMyAccountButton) {
      this.hideProfileMenu();
    }
  }
}
