import { Injectable } from '@angular/core';
import { config } from 'process';
import { Language } from 'src/app/interfaces/State/Language/language';
import { CONFIGURATION_MAP, Environment, Client } from 'src/configurations/configuration-map';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  constructor(

  ) { }

  async loadClientConfiguration(client: Client, environment: Environment): Promise<any> {
    try {
      const loadConfig = CONFIGURATION_MAP[environment as Environment][client] as () => Promise<any>;
      if (loadConfig) {
        const configModule = await loadConfig();
        return configModule.configuration
      } else {
        throw new Error(`Configuration not found for client: ${client} in environment: ${environment}`);
      }
    } catch (err) {
      console.error(`Failed to load configuration for client: ${client} in environment: ${environment}`, err);
      throw err;
    }
  }

  async isLanguageAvailable(client: Client, environment: Environment, languageSlug: string) {
    return this.loadClientConfiguration(client, environment)
    .then(config => {
      const languages = config.languages ?? []
      
      return languages.find((language: Language) => language.slug == languageSlug) ? true : false

    }).catch(error => {
      console.error('Error loading configuration:', error);
    });
  }
}
