<div class="min-h-[calc(100vh-170px)]">
    <div class="bg-white p-10 rounded-[5px] max-w-[calc(100%-30px)] w-[500px] mx-auto my-12">
        <h1 *ngIf="!success" class="mx-auto text-center uppercase">
            <span class="font-bold border-b-2 border-b-[var(--red)] text-[var(--red)]">{{ 'reset-password.set-new-password' | translate }}</span>
        </h1>
        <p *ngIf="error && !success" class=" mt-6  text-[var(--red)] text-center -mb-4 break-words" [innerHTML]="error"></p>
        <p *ngIf="success" class="text-center ">{{ 'reset-password.success-1' }}<span (click)="showLoginModal()" class="text-[var(--red)] font-bold cursor-pointer">{{ 'reset-password.success-2' | translate }}</span> {{ 'reset-password.success-3' | translate }}</p>
        <form class="mt-12" *ngIf="!success" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}">
            <div class="mb-5">
                <app-input 
                    [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''"
                    [placeholder]="'validation.email-address' | translate" 
                    (inputValueChangedEvent)="setValue('email', $event)"></app-input>

                <app-input-error-msg 
                    customCssClasses="pl-4" 
                    [show]="isFieldInvalid('email', 'required')"
                    [errorMsg]="'validation.email-address-required' | translate"></app-input-error-msg>

                <app-input-error-msg 
                    customCssClasses="pl-4" 
                    [show]="isFieldInvalid('email', 'email')"
                    [errorMsg]="'validation.email-address-invalid' | translate"></app-input-error-msg>
            </div>
            <div class="mb-5">
                <app-input type="password"
                    [customCssClasses]="isFieldInvalidAtAll('password') ? 'border border-[var(--red)]' : ''"
                    [placeholder]="'validation.password' | translate" 
                    (inputValueChangedEvent)="setValue('password', $event)"></app-input>
                <app-input-error-msg 
                    customCssClasses="pl-4" 
                    [show]="isFieldInvalid('password', 'required')"
                    [errorMsg]="'validation.password-required' | translate"></app-input-error-msg>
            </div>
            <div class="mb-5">
                <app-input type="password"
                    [customCssClasses]="isFieldInvalidAtAll('password_confirmation') ? 'border border-[var(--red)]' : ''"
                    [placeholder]="'validation.password-confirmation' | translate" 
                    (inputValueChangedEvent)="setValue('password_confirmation', $event)">
                </app-input>
                <app-input-error-msg customCssClasses="pl-4"
                    [show]="isFieldInvalid('password_confirmation', 'required')"
                    [errorMsg]="'validation.password-required' | translate"></app-input-error-msg>
            </div>
            <div>
                <app-button [isProcessing]="isRequesting">
                    {{!isRequesting ? ('reset-password.set-new-password' | translate) :  ('reset-password.setting-new-password' | translate)}}</app-button>
            </div>
        </form>
    </div>
</div>