import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent {
  @Input() link: string | Array<string> = ""
  @Input() cssClasses: string = ""

  public constructor()
  {
    if(Array.isArray(this.link)) {
      this.link = this.link.join('/')
    }
  }
}
