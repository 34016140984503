<ul class="absolute -right-[10px] top-[41px] z-10 bg-[var(--primary-color)] m-0 rounded-b-2xl min-w-[300px] max-w-[400px] shadow-solid">
    <ng-container *ngIf="!isCashier()">
        <li class="px-6 py-3 text-sm font-semibold uppercase"><a [routerLink]="['/my-tickets'] | localize">{{ 'profile-menu.my-tickets' | translate }}</a></li>
        <li class="px-6 py-3 text-sm font-semibold uppercase"><button (click)="invoiceDetails()" class="!uppercase">{{ 'profile-menu.invoice-details' | translate }}</button></li>
        <li class="px-6 py-3 text-sm font-semibold uppercase"><button (click)="showPasswordResetModal()" class="!uppercase">{{ 'profile-menu.change-password' | translate }}</button></li>
    </ng-container>
    <ng-container *ngIf="isCashier()">
        <li class="px-6 py-3 text-sm font-semibold uppercase"><a [routerLink]="['/orders'] | localize">{{ 'profile-menu.orders' | translate }}</a></li>
    </ng-container>
    <li class="px-6 py-3 text-sm font-semibold"><button type="button" class="!uppercase" (click)="logout()">{{ 'profile-menu.logout' | translate }}</button></li>
    <li class="px-6 py-3 text-sm bg-[var(--red)] rounded-b-2xl font-semibold break-words">{{ auth.user.email }}</li>
</ul>
