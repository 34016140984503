import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  state?: any

  constructor(
    private _store: Store,
    private _helperService: HelperService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this._store.subscribe(s => this.state = s);
    const currentLanguage = this._helperService.getCurrentLanguage()

    request = request.clone({
      setHeaders: {
        'Accept-Language': currentLanguage,
      }
    })
    return next.handle(request);
  }
}
