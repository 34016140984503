import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketComponent } from './ticket.component';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [TicketComponent],
  imports: [
    CommonModule,
    XModule,
    TranslateModule,
  ],
  exports : [
    TicketComponent,
  ]
})
export class TicketModule { }
