<section class="flex flex-col h-full relative">
    <div class="bg-[var(--red)] p-5 flex justify-between items-center">
        <p class="text-white font-black text-sm">{{ 'stadium.sectors-list.selected-tickets' | translate }}: <span class="hidden sm:inline" *ngIf="myTickets && myTickets.length">({{ myTickets.length }})</span></p>
        <p class="text-white font-black text-sm">{{ cart.total_price }}</p>
    </div>
    <div class="h-[calc(100%-120px)] overflow-scroll">
        <ul class="sm:pb-[180px]">
            <li *ngFor="let sector of sectors; index as i;" class="flex justify-between p-5 relative border-b border-b-[#d0d0d0]">
                <div>
                    <p class="text-sm font-black">{{ sector.n }}</p>
                    <p class="text-sm">{{ sector.p }}</p>
                </div>
                <div class="flex items-center gap-4" [ngClass]="{'opacity-30 cursor-progress' : isRequesting(sector.i)}">
                    <app-rounded-button [disabled]="isRequesting(sector.i) || getNumberOfTickets(sector.i) == 0" (buttonClicked)="decreaseTicket(sector.i)">-</app-rounded-button>
                    <span class="font-bold">{{ getNumberOfTickets(sector.i) }}</span>
                    <app-rounded-button [disabled]="isRequesting(sector.i)" (buttonClicked)="increaseTicket(sector.i)">+</app-rounded-button>
                </div>
            </li>
        </ul>
    </div>
    <a 
        id="to-cart" 
        [routerLink]="['/cart'] | localize" 
        class="p-5 block text-center bg-[var(--secondary-color)] hover:bg-[var(--secondary-color-hover)] transition-all text-white font-bold text-sm">{{ 'stadium.sectors-list.proceed-to-payment' | translate }}</a>
</section>
