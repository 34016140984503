import { Component, Input, OnInit } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { FixtureType } from 'src/app/enums/fixture/fixtureType';

@Component({
  selector: 'app-fixture-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent  {
  @Input() fixtures?: any
  @Input() needEmptyText: Boolean = true

  public fixtureTypes() {
    return FixtureType
  }

  public isFixtureTypes(fixture: any, eventType: string,) {
    return eventType == fixture.event_type
  }
}
