import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRegisterContentComponent } from './login-register-content.component';
import { LoginFormModule } from '../login-form/login-form.module';
import { RegisterFormModule } from '../register-form/register-form.module';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [LoginRegisterContentComponent],
  imports: [
    CommonModule,
    LoginFormModule,
    RegisterFormModule,
    ForgotPasswordComponent,
    TranslateModule,
  ],
  exports : [LoginRegisterContentComponent],
})
export class LoginRegisterContentModule { }
