import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyTicketsComponent } from './my-tickets.component';
import { BrowserModule } from '@angular/platform-browser';
import { DownloadModule } from 'src/app/components/assets/icons/download/download.module';
import { MyTicketModule } from 'src/app/components/my-ticket/my-ticket.module';
import { MySeasonPassModule } from 'src/app/components/my-season-pass/my-season-pass.module';
import { SkeletonLoaderComponent } from 'src/app/components/skeleton-loader/skeleton-loader.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MyTicketsComponent
  ],
  imports: [
    CommonModule,
    DownloadModule,
    MyTicketModule,
    MySeasonPassModule,
    SkeletonLoaderComponent,
    TranslateModule,
  ],
  exports: [
    MyTicketsComponent
  ],
})
export class MyTicketsModule { }
