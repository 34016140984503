import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MySeasonPassesComponent } from './my-season-passes.component';
import { MySeasonPassModule } from 'src/app/components/my-season-pass/my-season-pass.module';
import { DownloadModule } from 'src/app/components/assets/icons/download/download.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MySeasonPassesComponent,
  ],
  imports: [
    CommonModule,
    DownloadModule,
    MySeasonPassModule,
    TranslateModule,
  ],
  exports: [
    MySeasonPassesComponent,
  ],
})
export class MySeasonPassesModule { }
