<p *ngIf="error" class="text-[var(--red)] text-center mb-8 break-words" [innerHTML]="error"></p>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
    <div class="mb-5">
        <app-input [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''" [placeholder]="'validation.email-address' | translate" (inputValueChangedEvent)="setValue('email', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'required')" [errorMsg]="'validation.email-address-required' | translate"></app-input-error-msg>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'email')" [errorMsg]="'validation.email-address-invalid' | translate"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input type="password" [customCssClasses]="isFieldInvalidAtAll('password') ? 'border border-[var(--red)]' : ''" [placeholder]="'validation.password' | translate" (inputValueChangedEvent)="setValue('password', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('password', 'required')" [errorMsg]="'validation.password-required' | translate"></app-input-error-msg>
    </div>
    <div >
        <app-button [isProcessing] = "isRequesting">{{ 'login.login' | translate}}</app-button>
    </div>
    <button (click)="showForgotPasswordForm()" class="text-[#888888] mt-5 mb-5 block underline text-[13px]">{{ 'login.forgot-password' | translate }}</button>   
    <a (click)="showRegisterForm()" class="text-[#888888] cursor-pointer mt-5 mb-0 block text-[13px] text-center" [innerHTML]="'login.lets-register' | translate"></a>   
</form> 