import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { RouterModule } from '@angular/router';
import { TicketModule } from './_partials/ticket/ticket.module';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { SpinnerComponent } from 'src/app/components/assets/icons/spinner/spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';

@NgModule({
  declarations: [CartComponent],
  imports: [
    CommonModule,
    RouterModule,
    TicketModule,
    XModule,
    SpinnerComponent,
    TranslateModule,
    LocalizeRouterModule,
  ], 
  exports : [
    CartComponent,
  ]
})
export class CartModule { }
