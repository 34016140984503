<div class="p-10 drop-shadow-[0_0_25px_rgba(0,0,0,1)] bg-white relative mt-[10vh] rounded-[10px] w-[700px] max-w-[90vw] sm:!mt-[60px] max-h-[84vh] sm:max-h-[87vh] overflow-y-scroll">
    <button (click)="hideModal()" class="absolute top-[30px] right-[30px]" type="button" id="closeButton" role="button"><app-x></app-x></button>
    
    <h1 class="text-[15px] font-bold">{{ ticketTitle }}</h1>
    
    <div *ngIf="loading" class=" py-4">
        <p class="text-center">{{ 'general.loading' | translate }}</p>
    </div>

    <p *ngIf="error" class="text-[var(--red)] text-center mb-6 break-words mt-6" [innerHTML]="error"></p>
    <form *ngIf="!loading && !loadingCountries" [formGroup]="personalDetailsForm" (ngSubmit)="onSubmit()" class="mt-5" [ngClass]="{'is-requesting' : isRequesting}" >
        <div class="grid grid-cols-2 gap-5 mb-5 sm:grid-cols-1">
            <div *ngFor="let input of personalDetailsRequirements">
                <label class="mb-1 text-xs text-[#a7a7a7]">{{ input.label }}: <span *ngIf="input.required">*</span></label>
                
                <!-- INPUT -->
                <app-input 
                    *ngIf="input.type == 'text' || input.type == 'date'"
                    [required]="input.required" 
                    [value]="ticketPersonalDetails ? ticketPersonalDetails[input.name] : ''" 
                    [customCssClasses]="isFieldInvalidAtAll(input.name) ? 'border border-[var(--red)]' : ''" 
                    [placeholder]="input.label" 
                    [type]="input.type"
                    (inputValueChangedEvent)="setValue(input.name, $event)"
                ></app-input>

                <!-- SELECT -->
                <ng-select 
                    class="custom"
                    notFoundText=""
                    *ngIf="isSelect(input.type) && input.name == 'birth_country'" 
                    [items]="countries" 
                    #select
                    [virtualScroll]="false"
                    (change)="setValue(input.name, $event)"
                    [formControlName]="input.name"
                    bindLabel="label"
                    bindValue="id"
                    [compareWith]="compareCountry"
                ></ng-select>

                <app-input-error-msg *ngIf="input.required" customCssClasses="pl-4" [show]="isFieldInvalid(input.name, 'required')" [errorMsg]="input.required_text"></app-input-error-msg>
            </div>
        </div>
        <app-button  *ngIf="!isError(cartErrors().CART_NOT_FOUND)" [isProcessing]="isRequesting">{{ 'general.save' | translate }}</app-button>
    </form>

    <span *ngIf="loading || loadingCountries" class="text-center p-5 w-full block">{{ 'general.loading' | translate }}</span>

</div>