import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderResultComponent } from './order-result.component';
import { RouterModule } from '@angular/router';
import { MyTicketModule } from 'src/app/components/my-ticket/my-ticket.module';
import { MySeasonPassModule } from 'src/app/components/my-season-pass/my-season-pass.module';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OrderResultComponent],
  imports: [
    CommonModule,
    RouterModule,
    MyTicketModule,
    MySeasonPassModule,
    ButtonModule,
    TranslateModule,
    LocalizeRouterModule,
  ],
  exports : [OrderResultComponent]
})
export class OrderResultModule { }
