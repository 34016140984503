export const environment = {
  production: false,
  environment : 'staging',
  apiUrl: 'https://api.stage.csakjegyek.hu/ticketing-api/',
  client: '',
  clients : {
    test : {
      frontendEncryptDecryptKey: 'qWRl0TnDvekCJtCLD0bSmtX/ho0lcJ1k1ESt0A+rgNE=',
    },
    szpari: {
      frontendEncryptDecryptKey: 'qWRl0TnDvekCJtCLD0bSmtX/ho0lcJ1k1ESt0A+rgNE=',
    }
  }
};
