import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { lastValueFrom, Observable, take } from 'rxjs';
import { select, State, Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { CommonState } from 'src/app/interfaces/State/Common/common-state';
import { HelperService } from 'src/app/services/Misc/Helper/helper.service';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {
  state?: any

  constructor(
    private _store: Store,
    private _helperService: HelperService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) : Observable<HttpEvent<unknown>>{
    request = request.clone({
      setHeaders: {
        Client: this._helperService.getClient(),
      }
    })
    return next.handle(request);
  }
}
