<div class="bg-[var(--primary-color)] min-h-[calc(100vh-120px)]">
    <div class="container">
        <div class="flex justify-between">
            <section class="mb-5 w-full mt-10">
                <!-- REQUESTING -->
                <ng-container *ngIf="isRequesting">
                    <h1 class="text-white text-center text-[17px] font-black mb-16 mt-10 pl-10">{{ 'verify.activation-in-progress' | translate }}</h1>
                </ng-container>

                <!-- VALIDATED -->
                <ng-container *ngIf="validated">
                    <h1 class="text-white text-center text-[17px] font-black mb-2 mt-10 pl-10">{{ 'verify.successfully-verified' | translate }}</h1>
                    <div class="text-center">
                        <a class="inline-block text-white mb-16" [routerLink]="'/' | localize">{{ 'verify.to-home' | translate }}</a>
                    </div>
                    <app-fixture-list [fixtures]="fixtures.upcomingFixtures" ></app-fixture-list>
                </ng-container>
                
                <!-- ERROR -->
                <ng-container *ngIf="!isRequesting && error">
                    <h1 class="text-white text-center text-[17px] font-black mb-16 mt-10 pl-10" [innerHTML]="error"></h1>
                </ng-container>
            </section>
        </div>
    </div>
</div>
