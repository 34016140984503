<!-- LOADER -->
<ul id="fixtures" class="bg-white rounded-[20px] overflow-hidden" *ngIf="seasonPasses == undefined">
    <li *ngFor="let loader of [].constructor(5); let i = index;" >
        <app-skeleton-loader [row]="3.875" />
    </li>
</ul>

<!-- EMPTY STATE -->
<p class="text-white" *ngIf="!seasonPasses?.length && seasonPasses !== undefined && needEmptyText">{{ 'season-pass-list.no-result' | translate }}</p>

<!-- SEASON PASSES-->
<ul class="bg-white rounded-[20px]" *ngIf="seasonPasses?.length">
    <app-single-season-ticket *ngFor="let seasonPass of seasonPasses" [seasonPass]="seasonPass"></app-single-season-ticket>
</ul>
