<p class="error"  class="text-[var(--red)] text-center mb-8 break-words" [innerHTML]="error"></p>
<form [formGroup]="registerForm" (ngSubmit)="onSubmit()" [ngClass]="{'is-requesting' : isRequesting}" >
    <div class="mb-5">
        <app-input [customCssClasses]="isFieldInvalidAtAll('last_name') ? 'border border-[var(--red)]' : ''" [placeholder]="'validation.lastname' | translate" (inputValueChangedEvent)="setValue('last_name', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('last_name', 'required')" [errorMsg]="'validation.lastname_required' | translate"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input [customCssClasses]="isFieldInvalidAtAll('first_name') ? 'border border-[var(--red)]' : ''" [placeholder]="'validation.firstname'  | translate" (inputValueChangedEvent)="setValue('first_name', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('first_name', 'required')" [errorMsg]="'validation.firstname-required' | translate"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input [customCssClasses]="isFieldInvalidAtAll('email') ? 'border border-[var(--red)]' : ''" [placeholder]="'validation.email-address' | translate" (inputValueChangedEvent)="setValue('email', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'required')" [errorMsg]="'validation.email-address-required' | translate"></app-input-error-msg>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('email', 'email')" [errorMsg]="'validation.email-address-invalid' | translate"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input type="password" [customCssClasses]="isFieldInvalidAtAll('password') ? 'border border-[var(--red)]' : ''" [placeholder]="'validation.password' | translate" (inputValueChangedEvent)="setValue('password', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('password', 'required')" [errorMsg]="'validation.password-required' | translate"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-input type="password" [customCssClasses]="isFieldInvalidAtAll('password_confirmation') ? 'border border-[var(--red)]' : ''" [placeholder]="'validation.password-confirmation' | translate" (inputValueChangedEvent)="setValue('password_confirmation', $event)"></app-input>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('password_confirmation', 'required')" [errorMsg]="'validation.password-required' | translate"></app-input-error-msg>
    </div>
    <div class="mb-5">
        <app-checkbox (inputValueChangedEvent)="setValue('terms', $event)" identifier="terms" [label]="'registration.accept-terms' | translate"></app-checkbox>
        <app-input-error-msg customCssClasses="pl-4" [show]="isFieldInvalid('terms', 'required')" [errorMsg]="'validation.accepting-terms-is-required' | translate"></app-input-error-msg>
    </div>
    <div>
        <app-button [isProcessing] = "isRequesting">{{!isRequesting ? ('registration.register' | translate) : ('registration.registering' | translate)}}</app-button>
    </div>
</form> 