import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar.component';
import { LoginOrRegisterModule } from '../login-or-register/login-or-register.module';
import { NavbarModule as CartNavbarModule } from '../../../components/cart/navbar/navbar.module';
import { RouterModule } from '@angular/router';
import { ProfileMenuModule } from 'src/app/components/navbar/profile-menu/profile-menu.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';


@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    LoginOrRegisterModule,
    CartNavbarModule,
    RouterModule,
    ProfileMenuModule,
    TranslateModule,
    LocalizeRouterModule,
  ],
  exports: [NavbarComponent]
})
export class NavbarModule { }
