import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketComponent } from './ticket.component';
import { RouterModule } from '@angular/router';
import { TrashModule } from 'src/app/components/assets/icons/trash/trash.module';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';



@NgModule({
  declarations: [TicketComponent],
  imports: [
    CommonModule,
    RouterModule,
    TrashModule,
    XModule,
    TranslateModule,
    LocalizeRouterModule,
  ],
  exports: [TicketComponent],
})
export class TicketModule { }
