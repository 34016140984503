import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list.component';
import { LinkButtonModule } from '../../common/link-button/link-button.module';
import { ButtonModule } from '../../form/button/button.module';
import { SingleSeasonTicketModule } from './_partials/single-season-ticket/single-season-ticket.module';
import { SkeletonLoaderComponent } from '../../skeleton-loader/skeleton-loader.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ListComponent],
  imports: [
    CommonModule,
    LinkButtonModule,
    ButtonModule,
    SingleSeasonTicketModule,
    SkeletonLoaderComponent,
    TranslateModule,
  ],
  exports: [ListComponent],
})
export class ListModule { }
