import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsHolderComponent } from './tabs-holder.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [TabsHolderComponent],
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [
    TabsHolderComponent,
  ],
})
export class TabsHolderModule { }
