import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceDetailsComponent } from './invoice-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'src/app/components/form/button/button.module';
import { InputModule } from 'src/app/components/form/input/input.module';
import { InputErrorMsgModule } from 'src/app/components/form/error/input-error-msg/input-error-msg.module';
import { XModule } from 'src/app/components/assets/icons/x/x.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InvoiceDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputModule,
    InputErrorMsgModule,
    XModule,
    NgSelectModule,
    TranslateModule,
  ],
  exports : [InvoiceDetailsComponent]
})
export class InvoiceDetailsModule { }
