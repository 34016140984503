import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Cart } from 'src/app/interfaces/State/Cart/cart';
import { Modals } from 'src/app/interfaces/State/Modal/modals';
import { SectorWithSeats } from 'src/app/interfaces/State/Stadium/sector-with-seats';
import { AlertService } from 'src/app/services/Alert/alert.service';
import { CartService } from 'src/app/services/Cart/cart.service';
import { ModalService } from 'src/app/services/Modal/modal.service';

@Component({
  selector: 'app-standing-sector-ticket-selector',
  templateUrl: './standing-sector-ticket-selector.component.html',
  styleUrls: ['./standing-sector-ticket-selector.component.scss']
})
export class StandingSectorTicketSelectorComponent {
  private _subscription?: Subscription
  public formSubmitAttempt: boolean = false
  public requestingTicketType: { [key: string]: boolean } = {}
  public error: string = ""
  public cart: Cart = {} as Cart
  public modals: Modals = {} as Modals
  public fixtureSlug: string = ""
  public seasonPassSlug: string = ""
  public sector: SectorWithSeats = {} as SectorWithSeats

  constructor(
    private _modalService: ModalService,
    private _store: Store,
    private _cartService: CartService,
    private _alertService: AlertService,
    private _translateService: TranslateService,
  ) {

  }

  ngOnInit(): void {

    this._subscription = this._store.subscribe((state: any) => {
      this.cart = state.cart
      this.modals = state.modals
      this.sector = this.modals.modal.data.sector
      this.fixtureSlug = state.fixtures.openedFixture.id
    })
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe()
  }

  hideModal() {
    this._modalService.hideModal()
  }

  isRequesting(ticketTypeId: number) {
    return this.requestingTicketType[ticketTypeId] === true
  }

  /* get number of tickest for ticket type */
  getNumberOfTickets(ticketTypeId: number): number {
    const ticketsForCurrentEvent = this.cart?.items?.events?.[this.fixtureSlug]?.tickets

    let numberOfTickets = 0

    if (!ticketsForCurrentEvent) return 0

    Object.keys(ticketsForCurrentEvent).map((index: string) => {
      const ticket = ticketsForCurrentEvent[index];
      if (ticket.sector_id == this.sector.i && ticket.type_id == ticketTypeId) {
        numberOfTickets++
      }
    })

    return numberOfTickets
  }

  async increaseTicket(ticketType: number) { 
    if(this.requestingTicketType[ticketType]) return ;

    this.requestingTicketType[ticketType] = true
    const res = await this._cartService.bookRandomSeatForSectorWithTicketType(this.sector.i, this.fixtureSlug, ticketType)
    this.requestingTicketType[ticketType] = false
    if(res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const bookingSeatFailed = this._translateService.instant('stadium.seat.booking-seat-failed');

      this._alertService.error(errorHappenedText, res.error.error ?? bookingSeatFailed)
    }
  }

  async decreaseTicket(ticketType: number) {
    if(this.requestingTicketType[ticketType]) return ;

    const seat = this.getFirstTicket(ticketType)

    if(!seat) {
      const errorHappenedText = this._translateService.instant('general.error-happened');

      this._alertService.error(errorHappenedText)
      return ;
    }
    
    const seatId = seat.seat_id

    this.requestingTicketType[ticketType] = true
    const res = await this._cartService.removeSeat(seatId, this.fixtureSlug)
    delete this.requestingTicketType[ticketType]

    /* handle error */
    if(res.error) {
      const errorHappenedText = this._translateService.instant('general.error-happened');
      const removingSeatFailedText = this._translateService.instant('stadium.seat.removing-seat-failed');

      this._alertService.error(errorHappenedText, res.error.error ?? removingSeatFailedText)
    }
   }

    /* get first ticket */
  getFirstTicket(ticketTypeId: number) {
    const ticketsForCurrentEvent = this.cart?.items?.events?.[this.fixtureSlug]?.tickets
    /* if there is no ticket for event */
    if(!Object.keys(ticketsForCurrentEvent).length) { 
      return false
    }

    let selectedIndex: false | string = false

    /* get first ticket for sector */
    Object.keys(ticketsForCurrentEvent).map((index: string) => {
      const ticket = ticketsForCurrentEvent[index];
      if (ticket.sector_id == this.sector.i && ticket.type_id == ticketTypeId) {
        selectedIndex = index
        return 
      }
    })

    if(!selectedIndex) {
      return false
    }
    
    return ticketsForCurrentEvent[selectedIndex]
  }

}
